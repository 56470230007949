// ** Dropdowns Imports
import { Inbox, Users, Sun, Moon } from "react-feather";
import UserDropdown from "./UserDropdown";
import { Link } from "react-router-dom";
import { UncontrolledTooltip, Button } from "reactstrap";
import { useTranslation } from "react-i18next";

const NavbarUser = ({ skin, setSkin }) => {
  const { t } = useTranslation();

  // ** Function to toggle Theme (Light/Dark)
  const ThemeToggler = () => {
    if (skin === "dark") {
      return (
        <>
          <UncontrolledTooltip placement="bottom" target="light_theme">
            {t("light_theme")}
          </UncontrolledTooltip>
          <Button
            color="flat"
            className="nav-link-style nav-link"
            onClick={() => setSkin("light")}
            id="light_theme"
            style={{width: "35px"}}
          >
            <Sun className="ficon" />
          </Button>
        </>
      );
    } else {
      return (
        <>
          <UncontrolledTooltip placement="bottom" target="dark_theme">
            {t("dark_theme")}
          </UncontrolledTooltip>
          <Button
            color="flat"
            className="nav-link-style nav-link"
            onClick={() => setSkin("dark")}
            id="dark_theme"
            style={{width: "35px"}}
          >
            <Moon className="ficon" />
          </Button>
        </>
      );
    }
  };

  return (
    <>
      <ul className="nav navbar-nav align-items-center">
        <li className="d-none d-lg-block nav-item">
          <ThemeToggler />
        </li>
        <li className="d-none d-lg-block nav-item">
          <UncontrolledTooltip placement="bottom" target="newsletters">
            {t("newsletters")}
          </UncontrolledTooltip>
          <Link
            to="/newsletters"
            className="nav-link-style nav-link"
            id="newsletters"
          >
            <Users className="ficon" />
          </Link>
        </li>
        <li className="d-none d-lg-block nav-item">
          <UncontrolledTooltip placement="bottom" target="contact_us">
            {t("contact_us")}
          </UncontrolledTooltip>
          <Link
            to="/contact-us"
            className="nav-link-style nav-link"
            id="contact_us"
          >
            <Inbox className="ficon" />
          </Link>
        </li>
      </ul>
      <ul className="nav navbar-nav align-items-center ms-auto">
        <UserDropdown />
      </ul>
    </>
  );
};
export default NavbarUser;
