import { DefaultRoute } from "../router/routes";

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

// ** Returns K format from a number
export const kFormatter = (num) =>
  num > 999 ? `${(num / 1000).toFixed(1)}k` : num;

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "");

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date();
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  );
};

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: "short", day: "numeric", year: "numeric" }
) => {
  if (!value) return value;
  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value);
  let formatting = { month: "short", day: "numeric" };

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: "numeric", minute: "numeric" };
  }

  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => !!localStorage.getItem("token");
export const getUserData = () => JSON.parse(localStorage.getItem("userData"));

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === "admin") return DefaultRoute;
  if (userRole === "client") return "/access-control";
  return "/login";
};

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#7367f01a", // for option hover bg-color
    primary: "#1d71a1", // for selected option bg-color
    neutral10: "#1d71a1", // for tags bg-color
    neutral20: "#ededed", // for input border-color
    neutral30: "#ededed", // for input hover border-color
  },
});

export const ucfirst = (string) => {
  return string && string.charAt(0).toUpperCase() + string.slice(1);
};

// regex string starts with + minimum 10 digits max 17 digits
export const phoneNumberRegex = /^([0-9\s\-+()]*)$/;

export const urlValidationRegex =
  /((http|https):\/\/)(www.)?[a-z0-9\W]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-_?]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

export const addDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

// format date object to yyyy-mm-dd
export const formatDateToYMD = (date) => {
  if (!date) return date;
  if (typeof date === "string") return date;
  let month = `${date.getMonth() + 1}`;
  let day = `${date.getDate()}`;
  const year = date.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join("-");
};

export const generateFilter = (filters, col_names, query, state) => {
  const filterColumns = {};
  let betweenIndex = 0;
  Object.keys(filters).forEach((key, index) => {
    if (filters[key]?.operator && filters[key]?.value) {
      if (filters[key].operator === "between") {
        filterColumns[`filters[filter][column][${index}]`] = key;
        filterColumns[`filters[filter][operator][${index}]`] = "gt";
        filterColumns[`filters[filter][value][${index}]`] = formatDateToYMD(
          filters[key].value[0]
        );
        filterColumns[
          `filters[filter][column][${
            Object.keys(filters).length + betweenIndex
          }]`
        ] = key;
        filterColumns[
          `filters[filter][operator][${
            Object.keys(filters).length + betweenIndex
          }]`
        ] = "lt";
        filterColumns[
          `filters[filter][value][${
            Object.keys(filters).length + betweenIndex
          }]`
        ] = formatDateToYMD(filters[key].value[1]);
        betweenIndex = betweenIndex + 1;
      } else {
        filterColumns[`filters[filter][column][${index}]`] = key;
        filterColumns[`filters[filter][operator][${index}]`] =
          filters[key].operator;
        filterColumns[`filters[filter][value][${index}]`] =
          typeof filters[key].value === "object"
            ? formatDateToYMD(filters[key].value)
            : filters[key].value;
      }
    }
  });
  col_names({state}).forEach((one) => {
    filterColumns[`columns[${one?.filters?.name || one.name}][data]`] =
      one?.filters?.name || one.name;
    filterColumns[
      `columns[${one?.filters?.name || one.name}][searchable]`
    ] = true;
    filterColumns[
      `columns[${one?.filters?.name || one.name}][orderable]`
    ] = true;
    filterColumns[`columns[${one?.filters?.name || one.name}][search][value]`] =
      "";
    filterColumns[
      `columns[${one?.filters?.name || one.name}][search][regex]`
    ] = false;
  });

  filterColumns[`search[value]`] = query;
  filterColumns[`search[regex]`] = false;
  return filterColumns;
};

export const generateSaveFilters = (
  key_name,
  filters,
  col_names,
  query,
  orderBy,
  start,
  length,
  state
) => {
  const data = {};
  data["key_name"] = key_name;
  data["key_value[time]"] = new Date().getTime();
  data["key_value[start]"] = start;
  data["key_value[length]"] = length;
  data["key_value[order][]"] = orderBy.field ? orderBy.field : "";
  data["key_value[order][]"] = orderBy.order ? orderBy.order : "";
  data["key_value[search][search]"] = query;
  data["key_value[search][smart]"] = true;
  data["key_value[search][regex]"] = false;
  data["key_value[search][caseInsensitive]"] = true;
  col_names({state}).forEach((one) => {
    data[`key_value[columns][${one.name}][visible]`] = one.visible;
    data[`key_value[columns][${one.name}][search][search]`] = "";
    data[`key_value[columns][${one.name}][search][smart]`] = true;
    data[`key_value[columns][${one.name}][search][regex]`] = false;
    data[`key_value[columns][${one.name}][search][caseInsensitive]`] = false;
  });
  let betweenIndex = 0;
  Object.keys(filters).forEach((key, index) => {
    if (filters[key]?.operator && filters[key]?.value) {
      if (filters[key].operator === "between") {
        data[`key_value[filters][filter][column][${index}]`] = key;
        data[`key_value[filters][filter][operator][${index}]`] = "gt";
        data[`key_value[filters][filter][value][${index}]`] =
          filters[key].value[0];
        data[
          `key_value[filters][filter][column][${
            Object.keys(filters).length + betweenIndex
          }]`
        ] = key;
        data[
          `key_value[filters][filter][operator][${
            Object.keys(filters).length + betweenIndex
          }]`
        ] = "lt";
        data[
          `key_value[filters][filter][value][${
            Object.keys(filters).length + betweenIndex
          }]`
        ] = filters[key].value[1];
        betweenIndex = betweenIndex + 1;
      } else {
        data[`key_value[filters][filter][column][${index}]`] = key;
        data[`key_value[filters][filter][operator][${index}]`] =
          filters[key].operator;
        data[`key_value[filters][filter][value][${index}]`] =
          typeof filters[key].value === "object"
            ? formatDateToYMD(filters[key].value)
            : filters[key].value;
      }
    }
  });
  return data;
};

export const getAllIndexes = (arr, val) => {
  const indexes = [];
  for (let i = 0; i < arr.length; i++) if (arr[i] === val) indexes.push(i);
  return indexes;
};

// calculate date difference between two dates
export const dateDifference = (firstDate, secondDate) => {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
  return diffDays;
};

export const objectToArray = (obj) => {
  if (Array.isArray(obj)) {
    return obj;
  } else {
    return Object.keys(obj).map((key) => obj[key]);
  }
};

export const readSavedFilters = (filter_response) => {
  const filter = {};
  objectToArray(filter_response.column).forEach((one) => {
    const allIndexes = getAllIndexes(
      objectToArray(filter_response.column),
      one
    );
    if (allIndexes.length > 1) {
      filter[one] = {
        operator: "between",
        value: [
          new Date(objectToArray(filter_response.value)[allIndexes[0]]),
          new Date(objectToArray(filter_response.value)[allIndexes[1]]),
        ],
      };
    } else {
      filter[one] = filter_response?.operator &&
        filter_response?.value && {
          operator: objectToArray(filter_response.operator)[allIndexes[0]],
          value: objectToArray(filter_response.value)[allIndexes[0]],
        };
    }
  });

  return filter;
};
