// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "@src/utility/axiosInstance";
import toast from "react-hot-toast";

import CustomToast from "@components/CustomToast";

const qs = require("qs");

export const getCategories = createAsyncThunk(
  "categories/getCategories",
  async () => {
    const res = await axios(`/categories`);
    return res.data.data;
  }
);

export const getFeaturedCategories = createAsyncThunk(
  "categories/getFeaturedCategories",
  async () => {
    const res = await axios(`/categories/featured`);
    return res.data.data;
  }
);

export const getCategoriesMetaData = createAsyncThunk(
  "categories/getCategoriesMetaData",
  async () => {
    const res = await axios(`/categories/meta_data`);
    return res.data.data;
  }
);

export const showCategory = createAsyncThunk(
  "categories/showCategory",
  async (id) => {
    const response = await axios.get(`/categories/${id}`);
    return response.data.data;
  }
);

export const addCategory = createAsyncThunk(
  "categories/addCategory",
  async (category, { dispatch }) => {
    const res = await axios.post("/categories", category, {
      headers: { "Content-type": "multipart/form-data" },
    });
    if (res.status === 200) {
      await dispatch(getCategories());
      toast(<CustomToast type="success" message={res.data.message} />);
    }
    return res;
  }
);

export const changeMedia = createAsyncThunk(
  "categories/changeMedia",
  async ({ id, category }, { dispatch }) => {
    const res = await axios.post(`/categories/${id}/change_image`, category, {
      headers: { "Content-type": "multipart/form-data" },
    });
    if (res.status === 200) {
      await dispatch(getCategories());
    }
    return res.data.data;
  }
);

export const updateCategory = createAsyncThunk(
  "categories/updateCategory",
  async ({ id, category }, { dispatch }) => {
    const res = await axios.patch(`/categories/${id}`, null, {
      params: category,
    });
    if (res.status === 200) {
      await dispatch(getCategories());
      toast(<CustomToast type="success" message={res.data.message} />);
    }
    return res;
  }
);

export const saveFeaturedCategories = createAsyncThunk(
  "categories/saveFeaturedCategories",
  async ({ featuredCategories }) => {
    const res = await axios.patch(`/categories/featured`, null, {
      params: {
        categories_ids: featuredCategories,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    if (res.status === 200) {
      // await dispatch(getCategories());
      toast(<CustomToast type="success" message={res.data.message} />);
    }
    return res;
  }
);

export const deleteCategory = createAsyncThunk(
  "categories/deleteCategory",
  async (id, { dispatch }) => {
    const res = await axios.delete(`/categories/${id}`, null, { id });
    if (res.status === 200) {
      await dispatch(getCategories());
      toast(<CustomToast type="success" message={res.data.message} />);
    }
    return id;
  }
);

export const categoriesSlice = createSlice({
  name: "categories",
  initialState: {
    data: [],
    metaData: {  main_categories: []},
    featured: [],
  },

  reducers: {
    handleUpdateFeaturedItems: (state, action) => {
      state.featured = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCategories.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(getCategoriesMetaData.fulfilled, (state, action) => {
      state.metaData = action.payload;
    });
    builder.addCase(getFeaturedCategories.fulfilled, (state, action) => {
      state.featured = action.payload;
    });
  },
});

export const { handleUpdateFeaturedItems } = categoriesSlice.actions;

export default categoriesSlice.reducer;
