// ** Reducers Imports
import navbar from "./navbar";
import layout from "./layout";
import auth from "./authentication";
import districts from "@pages/content/districts/store";
import banners from "@pages/content/banners/store";
import testimonials from "@pages/content/testimonials/store";
import categories from "@pages/categories/store";
import services from "@pages/services/store";

const rootReducer = {
  auth,
  navbar,
  layout,
  districts,
  banners,
  categories,
  services,
  testimonials,
};

export default rootReducer;
