import { lazy } from "react";

const Banners = lazy(() => import("@pages/content/banners/list"));
const Testimonials = lazy(() => import("@pages/content/testimonials/list"));
const Districts = lazy(() => import("@pages/content/districts/list"));
const Pages = lazy(() => import("@pages/content/pages"));

const ContentRoutes = [
  {
    path: "/banners",
    element: <Banners />,
  },
  {
    path: "/testimonials",
    element: <Testimonials />,
  },
  {
    path: "/districts",
    element: <Districts />,
  },
  {
    path: "/pages",
    element: <Pages />,
  },
];

export default ContentRoutes;
