import { lazy } from "react";

const Services = lazy(() => import("@pages/services/list"));
const FeaturedServices = lazy(() => import("@pages/services/featured"));

const ServicesRoutes = [
  {
    path: "/services",
    element: <Services />,
  },
  {
    path: "/services/featured",
    element: <FeaturedServices />,
  },
];

export default ServicesRoutes;
