// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "@src/utility/axiosInstance";
import toast from "react-hot-toast";

import CustomToast from "@components/CustomToast";

const qs = require("qs");

export const getFeaturedServices = createAsyncThunk(
  "services/getFeaturedServices",
  async () => {
    const res = await axios(`/services/featured`);
    return res.data.data;
  }
);

export const getServicesMetaData = createAsyncThunk(
  "services/getServicesMetaData",
  async () => {
    const res = await axios(`/services/search`);
    return res.data.data;
  }
);

export const saveFeaturedServices = createAsyncThunk(
  "services/saveFeaturedServices",
  async ({ featuredServices }) => {
    const res = await axios.patch(`/services/featured`, null, {
      params: {
        services_ids: featuredServices,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    if (res.status === 200) {
      // await dispatch(getServices());
      toast(<CustomToast type="success" message={res.data.message} />);
    }
    return res;
  }
);

export const servicesSlice = createSlice({
  name: "services",
  initialState: {
    data: [],
    metaData: [],
    featured: [],
  },

  reducers: {
    handleUpdateFeaturedItems: (state, action) => {
      state.featured = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFeaturedServices.fulfilled, (state, action) => {
      state.featured = action.payload;
    });
    builder.addCase(getServicesMetaData.fulfilled, (state, action) => {
      state.metaData = action.payload;
    });
  },
});

export const { handleUpdateFeaturedItems } = servicesSlice.actions;

export default servicesSlice.reducer;
