// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "@src/utility/axiosInstance";

import toast from "react-hot-toast";

import CustomToast from "@components/CustomToast";

export const getTestimonials = createAsyncThunk(
  "testimonials/getTestimonials",
  async () => {
    const response = await axios.get(`/testimonials`);
    return response.data.data;
  }
);

export const showTestimonial = createAsyncThunk(
  "testimonials/showTestimonial",
  async (id) => {
    const response = await axios.get(`/testimonials/${id}`);
    return response.data.data;
  }
);

export const addTestimonial = createAsyncThunk(
  "testimonials/addTestimonial",
  async (testimonial, { dispatch }) => {
    const res = await axios.post(`/testimonials`, testimonial, {
      headers: { "Content-type": "multipart/form-data" },
    });
    res.status === 200 && (await dispatch(getTestimonials()));
    return res;
  }
);

export const changeMedia = createAsyncThunk(
  "testimonials/changeMedia",
  async ({ id, testimonial }, { dispatch }) => {
    const res = await axios.post(
      `/testimonials/${id}/change_image`,
      testimonial,
      {
        headers: { "Content-type": "multipart/form-data" },
      }
    );
    if (res.status === 200) {
      await dispatch(getTestimonials());
    }
    return res.data.data;
  }
);

export const updateTestimonial = createAsyncThunk(
  "testimonials/updateTestimonial",
  async ({ id, testimonial }, { dispatch }) => {
    const res = await axios.patch(`/testimonials/${id}`, null, {
      params: testimonial,
    });
    if (res.status === 200) {
      await dispatch(getTestimonials());
      toast(<CustomToast type="success" message={res.data.message} />);
    }
    return res;
  }
);

export const deleteTestimonial = createAsyncThunk(
  "testimonials/deleteTestimonial",
  async (id, { dispatch }) => {
    const res = await axios.delete(`/testimonials/${id}`, null, { id });
    if (res.status === 200) {
      await dispatch(getTestimonials());
      toast(<CustomToast type="success" message={res.data.message} />);
    }
    return res;
  }
);

export const toggleTestimonialPublishStatus = createAsyncThunk(
  "testimonials/toggleTestimonialPublishStatus",
  async (id, { dispatch }) => {
    const res = await axios.patch(`/testimonials/${id}/toggle_publish`, { id });
    if (res.status === 200) {
      await dispatch(getTestimonials());
      toast(<CustomToast type="success" message={res.data.message} />);
    }
    return res;
  }
);

export const testimonialsSlice = createSlice({
  name: "testimonials",
  initialState: {
    data: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTestimonials.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});

export default testimonialsSlice.reducer;
