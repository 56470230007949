import { lazy } from "react";

const Reviews = lazy(() => import("@pages/reviews/list"));

const ReviewsRoutes = [
  {
    path: "/reviews",
    element: <Reviews />,
  },
];

export default ReviewsRoutes;
