/** @format */

// ** React Imports
import { useCallback, useState } from "react";

// ** Third party library
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

// ** Custom components
import CustomToast from "@components/CustomToast";

// ** Utils and helpers
import { handleLogout } from "@store/authentication";
import { isUserLoggedIn } from "@utils";

const useFetch = () => {
  const [loading, setloading] = useState(false);
  const { token } = useSelector((s) => s.auth);
  const dispatch = useDispatch();
  const BASE_URL = `${process.env.REACT_APP_API_URL}/portal`;
  const navigate = useNavigate();
  const fetchData = useCallback(
    async ({
      body = {},
      headers = {},
      method = "GET",
      params = {},
      url,
      responseBody,
      status,
    }) => {
      setloading(true);

      const lang = localStorage.getItem("lang") || "";

      const req_headers = {
        Accept: "application/json",
        Lang: lang,
        ...headers,
      };

      if (token && isUserLoggedIn()) {
        req_headers.Authorization = `Bearer ${token}`;
      }

      const requestInfo = {
        method: method || "GET",
        headers: req_headers,
      };

      if (body && Object.keys(body).length) {
        const formData = new FormData();
        Object.keys(body).forEach((key) => {
          if (body[key] !== undefined) {
            if (Array.isArray(body[key])) {
              body[key].forEach((item, index) => {
                if (typeof item === "object") {
                  Object.keys(item).forEach((objectKey) => {
                    formData.append(
                      `${key}[${index}][${objectKey}]`,
                      item[objectKey]
                    );
                  });
                } else {
                  formData.append(`${key}[${index}]`, item);
                }
              });
            } else {
              formData.append(key, body[key]);
            }
          }
        });
        requestInfo.body = formData;
        // requestInfo.body = JSON.stringify(body);
      }

      Object.keys(params).forEach((key) => {
        if (key !== "for") {
          if (!params[key] || params[key] === "false") delete params[key];
        }
      });

      Object.keys(params).forEach((key, index) => {
        if (index === 0) {
          url += `?${key}=${encodeURIComponent(params[key])}`;
        } else {
          url += `&${key}=${encodeURIComponent(params[key])}`;
        }
      });

      const response = await fetch(url, requestInfo);
      // const response = await fetch(encodeURI(url), requestInfo);

      if (method === "DELETE") {
        const statusCode = response.status;
        if (statusCode === 204 && responseBody !== true) {
          return { statusCode };
        }
      }

      if (method === "POST") {
        const statusCode = response.status;
        if (statusCode === 204 || status === true) {
          return { statusCode };
        }
      }

      const data = await response.json();
      const statusCode = response.status;
      if (response.status === 401) {
        dispatch(handleLogout());
        navigate("/login");
      }
      if (!response.ok) {
        if (response.status === 422) {
          if (data.errors) {
            const message = Object.keys(data.errors).map(
              (key) => data.errors[key][0]
            );
            toast(<CustomToast type="error" message={message}/>);
          }
        } else {
          if (data?.message) {
            toast(<CustomToast type="error" message={data?.message}/>);
          }
        }
      }
      setloading(false);

      return { data, statusCode, response };
    },
    [dispatch, history, token]
  );

  const login = async ({ data }) => {
    return await fetchData({
      body: data,
      url: `${BASE_URL}/auth/login`,
      method: "POST",
    });
  };

  const logout = async () => {
    return await fetchData({
      url: `${BASE_URL}/auth/logout`,
      method: "POST",
    });
  };

  const services = async ({ params }) => {
    return await fetchData({
      url: `${BASE_URL}/services`,
      method: "GET",
      params,
    });
  };

  const advertisements = async ({ params }) => {
    return await fetchData({
      url: `${BASE_URL}/advertisements`,
      method: "GET",
      params,
    });
  };

  const get_useful_data = async ({ type, params }) => {
    return await fetchData({
      url: `${BASE_URL}/useful_data/${type}`,
      method: "GET",
      params,
    });
  };

  const get_newsletters = async ({ params }) => {
    return await fetchData({
      url: `${BASE_URL}/newsletters`,
      method: "GET",
      params,
    });
  };

  const get_reviews = async ({ params }) => {
    return await fetchData({
      url: `${BASE_URL}/reviews`,
      method: "GET",
      params,
    });
  };

  const get_contact_us = async ({ params }) => {
    return await fetchData({
      url: `${BASE_URL}/contact_us`,
      method: "GET",
      params,
    });
  };

  const show_useful_data = async (id, type) => {
    return await fetchData({
      url: `${BASE_URL}/useful_data/${type}/${id}`,
      method: "GET",
    });
  };

  const create_service = async ({ data }) => {
    return await fetchData({
      url: `${BASE_URL}/services`,
      method: "POST",
      body: data,
    });
  };

  const create_useful_data = async ({ type, data }) => {
    return await fetchData({
      url: `${BASE_URL}/useful_data/${type}`,
      method: "POST",
      body: data,
    });
  };

  const create_advertisement = async ({ data }) => {
    return await fetchData({
      url: `${BASE_URL}/advertisements`,
      method: "POST",
      body: data,
    });
  };
  const update_service = async ({ id, data }) => {
    return await fetchData({
      url: `${BASE_URL}/services/${id}`,
      method: "POST",
      body: data,
    });
  };

  const update_advertisement = async ({ id, data }) => {
    return await fetchData({
      url: `${BASE_URL}/advertisements/${id}`,
      method: "POST",
      body: data,
    });
  };

  const get_business_info = useCallback(
    async ({ params }) => {
      return await fetchData({
        url: `${BASE_URL}/preferences/system`,
        method: "GET",
        params,
      });
    },
    [fetchData]
  );

  const get_system_pref = useCallback(
    async ({ params }) => {
      return await fetchData({
        url: `${BASE_URL}/preferences/system`,
        method: "GET",
        params,
      });
    },
    [fetchData]
  );

  const update_business_info = async ({ data }) => {
    return await fetchData({
      url: `${BASE_URL}/preferences/system`,
      method: "POST",
      body: data,
    });
  };

  const change_media_services = async ({ id, data }) => {
    return await fetchData({
      url: `${BASE_URL}/services/${id}/change_image`,
      method: "POST",
      body: data,
    });
  };

  const change_media_advertisements = async ({ id, data }) => {
    return await fetchData({
      url: `${BASE_URL}/advertisements/${id}/change_image`,
      method: "POST",
      body: data,
    });
  };

  const update_system_pref = async ({ data }) => {
    return await fetchData({
      url: `${BASE_URL}/preferences/system`,
      method: "POST",
      body: data,
    });
  };

  const grid_preferences = useCallback(
    async (params) => {
      return await fetchData({
        url: `${BASE_URL}/preferences/grid`,
        method: "Get",
        params,
      });
    },
    [fetchData]
  );

  const save_grid_preferences = useCallback(
    async ({ data }) => {
      return await fetchData({
        url: `${BASE_URL}/preferences/grid`,
        method: "POST",
        body: data,
      });
    },
    [fetchData]
  );

  const services_metadata = useCallback(async () => {
    return await fetchData({
      url: `${BASE_URL}/services/meta_data`,
      method: "Get",
    });
  }, [fetchData]);

  const advertisements_metadata = useCallback(async () => {
    return await fetchData({
      url: `${BASE_URL}/advertisements/meta_data`,
      method: "Get",
    });
  }, [fetchData]);

  const update_user_profile = async ({ params }) => {
    return await fetchData({
      url: `${BASE_URL}/users/profile`,
      params,
      method: "PATCH",
    });
  };

  const update_password = async ({ params }) => {
    return await fetchData({
      url: `${BASE_URL}/auth/reset_password`,
      params,
      method: "PATCH",
    });
  };

  const update_pages = async ({ data }) => {
    return await fetchData({
      url: `${BASE_URL}/pages`,
      method: "POST",
      body: data,
    });
  };

  const visitors_and_views = async (params) => {
    return await fetchData({
      url: `${BASE_URL}/analytics_dashboard/page_view`,
      params,
    });
  };

  const audience_demographic = async (params) => {
    return await fetchData({
      url: `${BASE_URL}/analytics_dashboard/audience_demographics`,
      params,
    });
  };

  const audience_channel = async (params) => {
    return await fetchData({
      url: `${BASE_URL}/analytics_dashboard/audience_channel`,
      params,
    });
  };

  const audience_languages = async (params) => {
    return await fetchData({
      url: `${BASE_URL}/analytics_dashboard/audience_language`,
      params,
    });
  };

  const audience_overview = async (params) => {
    return await fetchData({
      url: `${BASE_URL}/analytics_dashboard/audience_overview`,
      params,
    });
  };

  const mobile_info = async (params) => {
    return await fetchData({
      url: `${BASE_URL}/analytics_dashboard/mobile_info`,
      params,
    });
  };

  const delete_service = async (id, response_body) => {
    return await fetchData({
      url: `${BASE_URL}/services/${id}`,
      method: "DELETE",
      responseBody: response_body,
    });
  };

  const delete_useful_data = async (type, id, response_body) => {
    return await fetchData({
      url: `${BASE_URL}/useful_data/${type}/${id}`,
      method: "DELETE",
      responseBody: response_body,
    });
  };

  const delete_review = async (id, response_body) => {
    return await fetchData({
      url: `${BASE_URL}/reviews/${id}`,
      method: "DELETE",
      responseBody: response_body,
    });
  };

  const update_useful_data = async (type, id, params) => {
    return await fetchData({
      url: `${BASE_URL}/useful_data/${type}/${id}`,
      params,
      method: "PATCH",
    });
  };

  const change_review_status = async (id, params) => {
    return await fetchData({
      url: `${BASE_URL}/reviews/${id}/change_status`,
      params,
      method: "PATCH",
    });
  };

  const toggle_status_useful_data = async (type, id) => {
    return await fetchData({
      url: `${BASE_URL}/useful_data/${type}/${id}/toggle_publish`,
      method: "PATCH",
    });
  };

  const toggle_status_newsletters = async (id) => {
    return await fetchData({
      url: `${BASE_URL}/newsletters/${id}/toggle_activation`,
      method: "PATCH",
    });
  };

  const get_users = useCallback(async () => {
    return await fetchData({
      url: `${BASE_URL}/users`,
    });
  }, [fetchData]);

  const create_user = async ({ data }) => {
    return await fetchData({
      url: `${BASE_URL}/users`,
      method: "POST",
      body: data,
    });
  };

  const update_user = async ({ id, params }) => {
    return await fetchData({
      url: `${BASE_URL}/users/${id}`,
      method: "PATCH",
      params,
    });
  };

  const update_avatar = async ({ id, data }) => {
    return await fetchData({
      url: `${BASE_URL}/users/${id}/update_avatar`,
      method: "POST",
      body: data,
    });
  };

  const reset_password = async (id) => {
    return await fetchData({
      url: `${BASE_URL}/users/${id}/reset_password`,
      method: "PATCH",
    });
  };

  const show_user = useCallback(
    async (id) => {
      return await fetchData({
        url: `${BASE_URL}/users/${id}`,
      });
    },
    [fetchData]
  );

  const show_service = useCallback(
    async (id) => {
      return await fetchData({
        url: `${BASE_URL}/services/${id}`,
      });
    },
    [fetchData]
  );

  const show_advertisement = useCallback(
    async (id) => {
      return await fetchData({
        url: `${BASE_URL}/advertisements/${id}`,
      });
    },
    [fetchData]
  );

  const toggle_activation = async (type, id) => {
    return await fetchData({
      url: `${BASE_URL}/${type}/${id}/toggle_activation`,
      method: "PATCH",
      status: true,
    });
  };

  const toggle_featured = async (id) => {
    return await fetchData({
      url: `${BASE_URL}/advertisements/${id}/toggle_featured`,
      method: "PATCH",
      status: true,
    });
  };

  const delete_advertisement = async (id, response_body) => {
    return await fetchData({
      url: `${BASE_URL}/advertisements/${id}`,
      method: "DELETE",
      responseBody: response_body,
    });
  };

  const show_pages = useCallback(async () => {
    return await fetchData({
      url: `${BASE_URL}/pages`,
    });
  }, [fetchData]);

  const search_services = useCallback(
    async (query) => {
      return await fetchData({
        url: `${BASE_URL}/services/search`,
        method: "GET",
        params: { query },
      });
    },
    [fetchData]
  );

  return {
    get_newsletters,
    search_services,
    delete_advertisement,
    toggle_activation,
    toggle_featured,
    show_user,
    show_service,
    show_advertisement,
    update_user,
    update_avatar,
    create_user,
    get_users,
    delete_service,
    delete_useful_data,
    delete_review,
    update_useful_data,
    change_review_status,
    toggle_status_useful_data,
    login,
    logout,
    loading,
    services,
    get_useful_data,
    get_reviews,
    get_contact_us,
    show_useful_data,
    advertisements,
    create_service,
    create_useful_data,
    update_service,
    update_advertisement,
    grid_preferences,
    save_grid_preferences,
    services_metadata,
    advertisements_metadata,
    create_advertisement,
    get_business_info,
    get_system_pref,
    update_business_info,
    change_media_services,
    change_media_advertisements,
    update_system_pref,
    update_user_profile,
    update_password,
    update_pages,
    visitors_and_views,
    audience_demographic,
    audience_channel,
    audience_languages,
    audience_overview,
    mobile_info,
    reset_password,
    BASE_URL,
    toggle_status_newsletters,
    show_pages,
  };
};

export default useFetch;
