// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "@src/utility/axiosInstance";
import toast from "react-hot-toast";

import CustomToast from "@components/CustomToast";

export const getBanners = createAsyncThunk("banners/getBanners", async () => {
  const response = await axios.get(`/banners`);
  return response.data.data;
});

export const showBanner = createAsyncThunk(
  "banners/showBanner",
  async (id) => {
    const response = await axios.get(`/banners/${id}`);
    return response.data.data;
  }
);

export const updateBanner = createAsyncThunk(
  "banners/updateBanner",
  async ({ id, banner }, { dispatch }) => {
    const res = await axios.patch(`/banners/${id}`, null, {
      params: banner,
    });
    if (res.status === 200) {
      await dispatch(getBanners());
      toast(<CustomToast type="success" message={res.data.message}/>);
    }
    return res;
  }
);

export const changeMedia = createAsyncThunk(
  "banners/changeMedia",
  async ({ id, banner }, { dispatch }) => {
    const res = await axios.post(`/banners/${id}/change_image`, banner, {
      headers: { "Content-type": "multipart/form-data" },
    });
    if (res.status === 200) {
      await dispatch(getBanners());
    }
    return res.data.data;
  }
);

export const bannersSlice = createSlice({
  name: "banners",
  initialState: {
    data: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBanners.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});

export default bannersSlice.reducer;
