import { lazy } from "react";

const Newsletters = lazy(() => import("@pages/newsletters/list"));

const NewsletterRoutes = [
  {
    path: "/newsletters",
    element: <Newsletters />,
  },
];

export default NewsletterRoutes;
