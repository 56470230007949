import axios from "axios";
import toast from "react-hot-toast";
import { isUserLoggedIn } from "@utils";

// ** Custom components
import CustomToast from "@components/CustomToast";

const BASE_URL = `${process.env.REACT_APP_API_URL}/portal`;

// Get token from the store and setup the auth
let store;

export const injectStore = (_store) => {
  store = _store;
};

axios.defaults.baseURL = BASE_URL;

axios.interceptors.request.use((config) => {
  // Auth token
  if (isUserLoggedIn()) {
    config.headers.authorization = `Bearer ${store.getState().auth.token}`;
  }
  // Lang
  const lang = localStorage.getItem("lang") || "en";
  config.headers.Lang = lang;
  return config;
});

// Handle errors
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    let message = error.response.data.message;
    if (error.response.data.errors) {
      message = "";
      Object.keys(error.response.data.errors).map((key) => {
        message += error.response.data.errors[key].join("\n");
      });
    }
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("userData");
      window.location.replace("/login");
      toast(<CustomToast type="error" message={message}/>);
    } else {
      toast(<CustomToast type="error" message={message}/>);
    }
  }
);

export default axios;
