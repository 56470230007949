import { lazy } from "react";

const Settings = lazy(() => import("@pages/settings"));
const Users = lazy(() => import("@pages/settings/users/list"));
const UsersUpdate = lazy(() => import("@pages/settings/users/actions/Update"));
const AccountSettings = lazy(() => import("@pages/account-settings"));
const BusinessProfile = lazy(() => import("@pages/settings/Business-profile"));

const SystemPreferences = lazy(() =>
  import("@pages/settings/system-preferences")
);

const SettingsRoutes = [
  {
    path: "/settings",
    element: <Settings />,
  },
  {
    path: "/account-settings",
    element: <AccountSettings />,
  },
  {
    path: "/users",
    element: <Users />,
  },
  {
    path: "/users/:id/update",
    element: <UsersUpdate />,
  },
  {
    path: "/business-profile",
    element: <BusinessProfile />,
  },
  {
    path: "/system-preferences",
    element: <SystemPreferences />,
  },
];

export default SettingsRoutes;
