import { useTranslation } from "react-i18next";
import { Check, Info, X } from "react-feather";
const CustomToast = ({ type, message }) => {
  const { t } = useTranslation();
  const typeMap = {
    success: {
      title: t("success"),
      defaultMessage: t("toast_message_success"),
      textColor: "text-success",
      icon: <Check className={"text-success me-50"} />,
    },
    error: {
      title: t("error"),
      defaultMessage: t("toast_message_error"),
      textColor: "text-danger",
      icon: <X className={"text-danger me-50"} />,
    },
    warning: {
      title: t("warning"),
      defaultMessage: t("toast_message_warning"),
      textColor: "text-warning",
      icon: <Info className={"text-warning me-50"} />,
    },
  };
  return (
    <div className="d-flex flex-column">
      <div className="d-flex align-items-center">
        {typeMap[type].icon}
        <h5 className={`${typeMap[type].textColor} mb-0`}>
          {typeMap[type].title}
        </h5>
      </div>
      <p className="mb-0">{message ? message : typeMap[type].defaultMessage}</p>
    </div>
  );
};

export default CustomToast;
