// ** Custom Components
import Avatar from "@components/avatar";
// ** Default Avatar Image
import defaultAvatar from "@src/assets/images/portrait/default.png";
import { handleLogout } from "@store/authentication";
// ** Utils
import { isUserLoggedIn } from "@utils";
import { useEffect, useState } from "react";
import { Power, Settings, User } from "react-feather";
// ** Store & Actions
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
// ** Third Party Components
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import useFetch from "@hooks/useFetch";
import { useTranslation } from "react-i18next";

const UserDropdown = () => {
  const userDataSelector = useSelector((s) => s.auth.userData);
  const { t } = useTranslation();
  // ** Store Vars
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { logout: apiLogout } = useFetch();

  // ** State
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [avatar, setAvatar] = useState(defaultAvatar);
  //** ComponentDidMount
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("userData"));
    if (isUserLoggedIn() !== null) {
      setUserData(JSON.parse(localStorage.getItem("userData")));
    }
    if (data?.avatar) {
      setAvatar(`${process.env.REACT_APP_STORAGE_URL}/avatars/${data?.avatar}`);
    }
  }, [userDataSelector]);
  const logout = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await apiLogout();
      dispatch(handleLogout());
      navigate("/login");
    } catch (err) {
      setLoading(false);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
      <DropdownToggle
        href="/"
        tag="a"
        className="nav-link dropdown-user-link"
        onClick={(e) => e.preventDefault()}
      >
        <div className="user-nav d-sm-flex d-none">
          <span className="user-name fw-bold">
            {(userData && userData.name) || t("placeholder_name")}
          </span>
          <span className="user-status">
            {(userData && userData.role) || t("admin")}
          </span>
        </div>
        <Avatar
          img={avatar}
          onError={() => {
            setAvatar(defaultAvatar);
          }}
          imgHeight="40"
          imgWidth="40"
          status="online"
        />
      </DropdownToggle>
      <DropdownMenu end>
        <DropdownItem tag={Link} to="/account-settings">
          <User size={14} className="me-75" />
          <span className="align-middle">{t("profile")}</span>
        </DropdownItem>

        <DropdownItem tag={Link} to="/settings">
          <Settings size={14} className="me-75" />
          <span className="align-middle">{t("settings")}</span>
        </DropdownItem>

        <DropdownItem tag={Link} to="/login" onClick={logout}>
          {loading ? <Spinner /> : <Power size={14} className="me-75" />}
          <span className="align-middle">{t("logout")}</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default UserDropdown;
