import { lazy } from "react";

const UsefulLinks = lazy(() => import("@pages/useful-data/useful-links/list"));
const EditUsefulLink = lazy(() =>
  import("@pages/useful-data/useful-links/actions/Update")
);
const EditUsefulNumber = lazy(() =>
  import("@pages/useful-data/useful-numbers/actions/Update")
);
const UsefulNumbers = lazy(() =>
  import("@pages/useful-data/useful-numbers/list")
);

const UsefulDataRoutes = [
  {
    element: <UsefulLinks />,
    path: "/useful-links",
  },
  {
    element: <EditUsefulLink />,
    path: "/useful-links/:id/update",
  },
  {
    element: <UsefulNumbers />,
    path: "/useful-numbers",
  },
  {
    element: <EditUsefulNumber />,
    path: "/useful-numbers/:id/update",
  },
];

export default UsefulDataRoutes;
