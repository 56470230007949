import { lazy } from "react";

const Advertisements = lazy(() => import("@pages/advertisements/list"));

const AdvertisementsRoutes = [
  {
    path: "/advertisements",
    element: <Advertisements />,
  },
];

export default AdvertisementsRoutes;
