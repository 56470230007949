import { lazy } from "react";

const Categories = lazy(() => import("@pages/categories/list"));
const FeaturedCategories = lazy(() => import("@pages/categories/featured"));

const CategoriesRoutes = [
  {
    path: "/categories",
    element: <Categories />,
  },
  {
    path: "/categories/featured",
    element: <FeaturedCategories />,
  },
];

export default CategoriesRoutes;
