import { lazy } from "react";

const ContactUs = lazy(() => import("@pages/contact-us/list"));

const ContactUsRoutes = [
  {
    path: "/contact-us",
    element: <ContactUs />,
  },
];

export default ContactUsRoutes;