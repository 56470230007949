// ** React Imports
import { Outlet } from "react-router-dom"

// ** Core Layout Import
// !Do not remove the Layout import
import Layout from "@layouts/VerticalLayout"

// ** Menu Items Array
import navigation from "@src/navigation/vertical"

// ** Custom navbar
import CustomNavbar from "./components/navbar"

// ** Custom footer
import CustomFooter from "./components/footer"

const VerticalLayout = (props) => {
  // const [menuData, setMenuData] = useState([])

  // ** For ServerSide navigation
  // useEffect(() => {
  //   axios.get(URL).then(response => setMenuData(response.data))
  // }, [])
  const CustomMenu = (props) => {
    console.log(props)
    return (
      <ul>
        <li>Menu Item 1</li>
        <li>Menu Item 2</li>
        <li>Menu Item 3</li>
      </ul>
    )
  }
  return (
    <Layout
      navbar={(props) => <CustomNavbar {...props} />}
      footer={<CustomFooter />}
      menuData={navigation}
      // menu={props => <CustomMenu {...props} />} 
      {...props}
    >
      <Outlet />
    </Layout>
  )
}

export default VerticalLayout
