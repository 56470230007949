import {
  BarChart2,
  Link,
  Phone,
  Grid,
  Clipboard,
  Radio,
  HardDrive,
  Edit,
  Image,
  MessageCircle,
  FileText,
  Map,
  File
} from "react-feather";

export default [
  {
    id: "analytics",
    title: "analytics",
    icon: <BarChart2 size={20} />,
    navLink: "/analytics",
  },
  {
    id: "categories",
    title: "categories",
    icon: <Grid size={20} />,
    navLink: "/categories",
  },
  {
    id: "services",
    title: "services",
    icon: <Clipboard size={20} />,
    navLink: "/services",
  },
  {
    id: "reviews",
    title: "reviews",
    icon: <FileText size={20} />,
    navLink: "/reviews",
  },
  {
    id: "advertisements",
    title: "advertisements",
    icon: <Radio size={20} />,
    navLink: "/advertisements",
  },
  {
    id: "useful_data",
    title: "useful_data",
    icon: <HardDrive size={20} />,
    children: [
      {
        id: "useful_links",
        title: "useful_links",
        icon: <Link size={20} />,
        navLink: "/useful-links",
      },
      {
        id: "useful_numbers",
        title: "useful_numbers",
        icon: <Phone size={20} />,
        navLink: "/useful-numbers",
      },
    ],
  },
  {
    id: "content",
    title: "content",
    icon: <Edit size={20} />,
    children: [
      {
        id: "banners",
        title: "banners",
        icon: <Image size={20} />,
        navLink: "/banners",
      },
      {
        id: "testimonials",
        title: "testimonials",
        icon: <MessageCircle size={20} />,
        navLink: "/testimonials",
      },
      {
        id: "districts",
        title: "districts",
        icon: <Map size={20} />,
        navLink: "/districts",
      },
      {
        id: "pages",
        title: "pages",
        icon: <File size={20} />,
        navLink: "/pages",
      },
    ],
  },
];
