import { lazy } from "react";

const Login = lazy(() => import("@pages/Login"));
const Error = lazy(() => import("@pages/Error"));
const Analytics = lazy(() => import("@pages/analytics"));

const OtherRoutes = [
  {
    path: "/login",
    element: <Login />,
    meta: {
      publicRoute: true,
      layout: "blank",
    },
  },
  {
    path: "/analytics",
    element: <Analytics />,
  },
  {
    path: "/*",
    element: <Error />,
    meta: {
      publicRoute: true,
      layout: "blank",
    },
  },
];

export default OtherRoutes;
