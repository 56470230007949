// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "@src/utility/axiosInstance";

import toast from "react-hot-toast";

// ** Custom components
import CustomToast from "@components/CustomToast";

export const getDistricts = createAsyncThunk(
  "districts/getDistricts",
  async () => {
    const response = await axios.get(`/districts`);
    return response.data.data;
  }
);

export const addDistrict = createAsyncThunk(
  "districts/addDistrict",
  async (district, { dispatch }) => {
    const res = await axios.post(`/districts`, district, {
      headers: { "Content-type": "multipart/form-data" },
    });
    res.status === 200 && (await dispatch(getDistricts()));
    return res;
  }
);

export const showDistrict = createAsyncThunk(
  "districts/showDistrict",
  async (id) => {
    const response = await axios.get(`/districts/${id}`);
    return response.data.data;
  }
);

export const updateDistrict = createAsyncThunk(
  "districts/updateDistrict",
  async ({ id, district }, { dispatch }) => {
    const res = await axios.patch(`/districts/${id}`, null, {
      params: district,
    });
    if (res.status === 200) {
      await dispatch(getDistricts());
      toast(<CustomToast type="success" message={res.data.message} />);
    }
    return res;
  }
);

export const deleteDistrict = createAsyncThunk(
  "districts/deleteDistrict",
  async (id, { dispatch }) => {
    const res = await axios.delete(`/districts/${id}`, null, { id });
    if (res.status === 200) {
      await dispatch(getDistricts());
      toast(<CustomToast type="success" message={res.data.message} />);
    }
    return id;
  }
);

export const districtsSlice = createSlice({
  name: "districts",
  initialState: {
    data: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDistricts.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});

export default districtsSlice.reducer;
